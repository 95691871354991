import { YearScope } from "./year-scope.interface";

export const yearScopes = [
  {
    shortKey: '2019/2020',
    year: '2020',
    harvestYear: '2019/2020'
  },
  {
    shortKey: '2020/2021',
    year: '2021',
    harvestYear: '2020/2021'
  },
  {
    shortKey: '2021/2022',
    year: '2022',
    harvestYear: '2021/2022'
  }
] as YearScope[];
